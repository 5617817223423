<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{ $t('import.add') }} {{ $t('import.add.fromCsv') }}</h2>
    <div class="c-main">
      <form class="c-main--flex-1">
        <div class="o-layout">
          <div class="o-layout__item u-1-of-3-at-medium">
            <div class="c-input__field-upload" :class="ready ? 'is-uploaded' : ''">
              <input type="file" name="upload" :disabled="reading"
                     draggable="true" v-on:dragleave="dragleave($event)" v-on:dragenter="dragenter($event)"
                     @change="fileChange($event.target.name, $event.target.files); fileCount = $event.target.files.length; files = $event.target.files"
                     accept="text/csv" class="input-file">
              <svg role="img" class="c-button__icon">
                <use xlink:href="images/svg-symbols.svg#csv-upload"></use>
              </svg>
              <p class="c-input__field-upload-message" v-if="! activeFile">{{ $t('import.dragFile') }}</p>
              <p class="c-input__field-upload-message" v-if="reading">Reading file</p>
              <p class="c-input__field-upload-message" v-if="ready">{{ files[0].name }}</p>
            </div>
          </div>

          <div class="o-layout__item u-2-of-3-at-medium o-flex o-flex--align-end">
            <ul class="o-list o-list-bare c-input--extra-info">
              <li class="o-list__item">{{ $t('import.fileConstraints1') }}</li>
              <li class="o-list__item">{{ $t('import.fileConstraints2') }}</li>
              <li class="o-list__item">{{ $t('import.fileConstraints3') }}</li>
            </ul>
          </div>
        </div>

        <fields v-if="ready" v-model="selection" :assignFields="true" :fieldLabels="importData.fields"
                :discipline="discipline"/>
      </form>
    </div>

    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
      </div>
      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <router-link exact :to="{ name: 'admin.event.discipline.import'}">
          <button class="c-button c-button--ghost c-button--large">{{ $t("cancel") }}</button>
        </router-link>
        <button class="c-button c-button--primary c-button--large" v-on:click.prevent="submit()"
                :class="{'c-button--disabled': ! selection.valid}">
          {{ $t('continue') }}
        </button>
      </div>

    </footer>
  </div>
</template>

<script>
const papa = require('papaparse');

import forOwn from 'lodash/forOwn';
import map from 'lodash/map';

export default {
  name: "import-add",
  components: {
    fields: require('./fields.vue').default,
  },
  data: function () {
    return {
      data: undefined,
      activeFile: false,
      reading: false,
      ready: false,
      sending: false,
      fileCount: 0,
      files: [],
      importData: [],
      selection: {
        fields: {},
        valid: false,
      },
    };
  },
  computed: {
    discipline: function () {
      return this.$store.state.eventDiscipline.discipline;
    },
  },
  methods: {
    fileChange: function (name, files) {
      this.readFile(files[0]);
    },
    readFile: function (file) {
      this.activeFile = true;
      this.reading = true;
      this.importData = {};
      const reader = new FileReader();
      reader.onload = () => {
        const fileInput = reader.result;
        this.importData = papa.parse(fileInput, {
          header: true,
          skipEmptyLines: true
        });
        this.importData.fields = this.importData.meta.fields.map(item => {
          return {name: item};
        });
        this.reading = false;
        this.ready = true;
      };
      reader.readAsText(file);
    },
    getSections: function () {
      let sections = [];
      forOwn(this.selection.fields, function (group, group_key) {
        if (!group.enabled || !group.import) {
          return;
        }

        sections.push(group_key);
      });
      return sections;
    },
    prepareData: function () {
      return map(this.importData.data, item => {
        let fields = {};
        forOwn(this.selection.fields, function (group, group_key) {
          if (!group.enabled || !group.import) {
            return;
          }

          let group_fields = {};
          forOwn(group.fields, function (field, field_key) {
            if (field.value) {
              group_fields[field_key] = item[field.value];
            }
          });
          fields[group_key] = group_fields;
        });

        return fields;
      });
    },

    submit: function () {
      const data = {
        type: 'file',
        sections: this.getSections(),
      };

      data.filename = this.files[0].name;
      data.fileData = this.prepareData();
      data.fields = this.selection.fields;
      data.dateFormat = 'DD/MM/YYYY'

      this.$store.dispatch('import.add', data).then((result) => {
        this.$navigation.goto({name: 'admin.event.discipline.import.view', params: {importId: result.id}});
      });
    },
    dragleave: function (e) {
      e.preventDefault();
      e.target.parentNode.classList.remove('is-uploaded');
    },
    dragenter: function (e) {
      e.preventDefault();
      e.target.parentNode.classList.add('is-uploaded');
    }
  }
};
</script>
