<template>
  <table class="o-table o-table--fixed c-table--results">
    <thead>
    <tr>
      <th class="c-table-cell__valign-bottom" rowspan="2">
        <div class="c-table__cell-content">
          {{$t('import.type')}}
        </div>
      </th>
      <th colspan="2">
        {{$t('import.prepared')}}
      </th>
      <th colspan="2">
        {{$t('import.processed')}}
      </th>
    </tr>
    <tr>
      <th class="c-table-cell__valign-bottom is-first">
        {{$t('import.new')}}
      </th>
      <th class="c-table-cell__valign-bottom">
        {{$t('import.update')}}
      </th>
      <th class="c-table-cell__valign-bottom">
        {{$t('import.finished')}}
      </th>
      <th class="c-table-cell__valign-bottom">
        {{$t('import.error')}}
      </th>
      <th class="c-table-cell__valign-bottom is-first">
        {{$t('import.details')}}
      </th>
    </tr>
    </thead>
    <tbody class="c-table__body">
    <tr v-for="(group, key) in importData.fileData" :key="key">
      <td class="c-table__grouped-cells">
        <div class="c-table__cell-content">
          {{$t('import.group.' + key)}}
        </div>
      </td>
      <td class="c-table__grouped-cells is-first">
        <div class="c-table__cell-content">
          {{ group.counts.create ? group.counts.create : '-'}}
        </div>
      </td>
      <td class="c-table__grouped-cells">
        <div class="c-table__cell-content">
          {{ group.counts.update ? group.counts.update : '-'}}
        </div>
      </td>
      <td class="c-table__grouped-cells is-first">
        <div class="c-table__cell-content">
          {{ group.counts.finished ? group.counts.finished : '-'}}
        </div>
      </td>
      <td class="c-table__grouped-cells">
        <div class="c-table__cell-content">
          {{ group.counts.error ? group.counts.error : '-'}}
        </div>
      </td>
      <td class="c-table__grouped-cells is-first">
        <div class="c-table__cell-content">
          <router-link exact :to="{ name: 'admin.event.discipline.import.view.detail', params: {importType: key}}">
            {{ $t('import.details.view') }}
          </router-link>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
  export default {
    name: "import-view-summary",
    computed: {
      importData: function() {
        return this.$store.state.imports.importData;
      },
    },
    methods: {
    },
  }
</script>
