<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{ $t('import.add') }} {{ $t('import.add.fromFederation') }}</h2>
    <div class="c-main">
      <form class="c-main--flex-1">
        <div v-if="status === 'filter'" class="o-layout">
          <div class="o-layout__item">
            <h3 class="c-title c-title--page-section">{{ $t('federationImport.filterTitle') }}</h3>
          </div>
          <div class="o-layout__item u-1-of-2-at-medium">
            <div class="c-input p-date-picker">
              <label class="c-input__label" for="dates">{{ $t('date') }}</label>
              <HotelDatePicker format="DD - MMM - YYYY" id="dates" name="dates"
                               :startingDateValue="filter.dateFrom" :endingDateValue="filter.dateTo"
                               :startDate="new Date(1990,1,1)" :endDate="new Date(2200,1,1)"
                               :showYear="true" :i18n="$options.labelsDatePicker" :minNights="0" :firstDayOfWeek="1"
                               @check-in-changed="updateFromDate($event)" @check-out-changed="updateToDate($event)"
              />
            </div>
          </div>
          <div class="o-layout__item u-1-of-2-at-medium">
            <sceTextField v-model="filter.title" name="title" label="federationImport.filter.title"/>
          </div>
          <div class="o-layout__item">
            <sceButton class="c-button--primary c-button--large" v-on:click="fetchEvents()"
                       label="federationImport.fetch"/>
          </div>
        </div>
        <div class="o-layout" v-if="status === 'loading'">
          <div class="o-layout__item">
            <p class="">{{ $t('federationImport.loadingEvents') }}</p>
            <waiting/>
          </div>
        </div>
        <div class="o-layout" v-if="status === 'error'">
          <div class="o-layout__item">
            <p>{{ $t('federationImport.error') }}</p>
            <p>{{ statusErr.status }} - {{ statusErr.statusText }}</p>
            <code>{{ statusErr.bodyText }}</code>
          </div>
        </div>
        <div class="o-layout" v-if="status === 'events'">
          <div class="o-layout__item">
            <h3 class="c-title c-title--page-section">{{ $t('federationImport.eventResults') }}</h3>
          </div>
          <div class="o-layout__item u-5-of-7-at-medium">
            <p>{{ $t('federationImport.filterTitle') }}: {{ getFilterStr() }}</p>
          </div>
          <div class="o-layout__item u-2-of-7-at-medium">
            <sceButton class="c-button--secondary c-button--small" v-on:click="changeFilter()"
                       label="federationImport.changeFilter"/>
          </div>
          <div class="o-layout__item">
            <grid :config="{nosearchBar: true, orderButtons: false}" :data="events">
              <template slot="gridHeader">
                <div class="o-layout c-list__row u-padding-horizontal-small">
                  <div class="o-layout__item u-5-of-7-at-medium">
                    {{ $t('title') }}
                  </div>
                  <div class="o-layout__item u-2-of-7-at-medium">
                    {{ $t('date') }}
                  </div>
                </div>
              </template>
              <template slot="gridData" slot-scope="slotProps">
                <div class="o-layout__item u-5-of-7-at-medium c-list__main-cell u-padding-bottom-medium">
                  {{ slotProps.element.title }}
                </div>
                <div class="o-layout__item u-2-of-7-at-medium">
                  {{ slotProps.element.dateFrom }} - {{ slotProps.element.dateTo }}
                </div>
                <template v-for="eventDisc of slotProps.element.disciplines">
                  <div :key="'eventDiscTitle-'+eventDisc.id"
                       class="o-layout__item u-5-of-7-at-medium c-list__main-cell u-padding-top-small">
                    * {{ eventDisc.title }} ({{ eventDisc.id }})
                  </div>
                  <div :key="'eventDiscButton-'+eventDisc.id"
                       class="o-layout__item u-2-of-7-at-medium u-padding-top-small">
                    <sceButton class="c-button--primary c-button--small" v-on:click="selectEventDisc(eventDisc)"
                               label="select"/>
                  </div>
                </template>
              </template>
            </grid>
          </div>
        </div>
        <template v-if="status === 'selectFields'">
          <div class="o-layout">
            <div class="o-layout__item">

            </div>
          </div>
          <fields v-model="selection"
                  :assignFields="false" :discipline="discipline" :sections="importData.sections"/>
        </template>
      </form>
    </div>

    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
      </div>
      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <router-link exact :to="{ name: 'admin.event.discipline.import'}">
          <button class="c-button c-button--ghost c-button--large">{{ $t("cancel") }}</button>
        </router-link>
        <button class="c-button c-button--primary c-button--large" v-on:click.prevent="submit()"
                :class="{'c-button--disabled': ! selection.valid}">
          {{ $t('continue') }}
        </button>
      </div>

    </footer>
  </div>
</template>

<script>
import lang from "client/lang";

const moment = require('moment');

import find from 'lodash/find';
import forOwn from 'lodash/forOwn';
import sortBy from 'lodash/sortBy';
import union from 'lodash/union';

import waiting from "client/components/waiting";
import HotelDatePicker from "vue-hotel-datepicker";

export default {
  name: "import-add-fed",
  components: {
    grid: require('client/components/dataGrid.vue').default,
    fields: require('./fields.vue').default,
    HotelDatePicker: HotelDatePicker,
    waiting,
  },
  data: function () {
    return {
      status: 'filter',
      statusErr: null,
      filter: {
        dateFrom: new Date(),
        dateTo: moment().add(4, 'weeks').toDate(),
        title: '',
      },
      events: undefined,
      importData: {},
      selection: {
        fields: {},
        valid: false,
      },
      eventDisc: null,
    };
  },
  computed: {
    discipline: function () {
      return this.$store.state.eventDiscipline.discipline;
    },
    eventUser: function () {
      const event = this.$store.state.events.current
      const user = this.$store.state.user.instance;
      if (user.id === event.owner_id) {
        return user;
      }
      return find(this.$store.state.users.items, item => item.id === event.owner_id);
    },
  },
  created: function () {
    this.$options.labelsDatePicker = {
      night: this.$t('day'),
      nights: this.$t('days'),
      'day-names': lang.getDays(),
      'check-in': this.$t('date.start').trim(),
      'check-out': this.$t('date.end').trim(),
      'month-names': lang.getMonths(),
    };
  },
  methods: {
    updateFromDate: function (newDate) {
      this.filter.dateFrom = newDate;
    },
    updateToDate: function (newDate) {
      this.filter.dateTo = newDate;
    },
    getFilterStr: function () {
      let filter = this.$t('date') + ': ';
      filter += moment(this.filter.dateFrom).format('DD-MM-YYYY');
      filter += ' -' + moment(this.filter.dateTo).format('DD-MM-YYYY');
      if (this.filter.title) {
        filter += ' / ' + this.$t('title') + ': ' + this.filter.title;
      }
      return filter;
    },
    changeFilter: function () {
      this.status = 'filter';
    },

    fetchCMS: function (url, params = {}) {
      const headers = {};
      switch (this.eventUser.configuration.federationImport.authMethod) {
        case 'basic': {
          const username = this.eventUser.configuration.federationImport.authUsername;
          const password = this.eventUser.configuration.federationImport.authKey;
          headers['Authorization'] = 'Basic ' + btoa(username + ':' + password);
          break;
        }
        case 'bearer': {
          const token = this.eventUser.configuration.federationImport.authKey;
          headers['Authorization'] = 'Bearer ' + token;
          break;
        }
      }
      headers['Access-Control-Allow-Origin'] = '*'

      return this.$http.get(url, {params, headers});
    },
    fetchEvents: function () {
      this.status = 'loading';
      const url = this.eventUser.configuration.federationImport.eventsURL;

      const params = {
        dateFrom: moment(this.filter.dateFrom).format('YYYY-MM-DD'),
        dateTo: moment(this.filter.dateTo).format('YYYY-MM-DD'),
      }
      if (this.filter.title) {
        params.title = this.filter.title;
      }

      this.fetchCMS(url, params).then(response => {
        console.log(response);
        this.events = sortBy(response.data, 'dateFrom');
        this.status = 'events';
      }, err => {
        console.log(err);
        this.status = 'error';
        this.statusErr = err;
      });
    },

    selectEventDisc: function (eventDisc) {
      this.eventDisc = eventDisc;
      this.status = 'loading';
      const url = this.eventUser.configuration.federationImport.disciplineUrl + '/' + eventDisc.id;
      this.importData = {};
      this.fetchCMS(url).then(response => {
        this.importData.data = response.data;

        let sections = [];
        this.importData.data.forEach(item => {
          const sections2 = []
          forOwn(item, (group, key) => {
            sections2.push(key);
          });
          sections = union(sections, sections2);
        });
        this.importData.sections = sections;

        this.status = 'selectFields';
      }, err => {
        console.log(err);
        this.status = 'error';
        this.statusErr = err;
      });
    },

    getSections: function () {
      let sections = [];
      forOwn(this.selection.fields, function (group, group_key) {
        if (!group.enabled || !group.import) {
          return;
        }

        sections.push(group_key);
      });
      return sections;
    },

    submit: function () {
      const data = {
        type: 'file',
        competitionId: this.eventDisc.id,
        sections: this.getSections(),
      };

      data.filename = this.eventDisc.title
      data.fileData = this.importData.data
      data.fields = this.selection.fields
      data.dateFormat = 'YYYY-MM-DD'

      this.$store.dispatch('import.add', data).then((result) => {
        this.$navigation.goto({name: 'admin.event.discipline.import.view', params: {importId: result.id}});
      });
    },
  }
};
</script>
