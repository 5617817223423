<template>
  <div>
    <div class="o-layout__item">
      <div style="float: right">
        <router-link exact :to="{ name: 'admin.event.discipline.import.view'}">
          X {{$t('close')}}
        </router-link>
      </div>
      <h3 class="c-title c-title--page-section">
        {{$t('import.details')}} {{$t('import.group.' + $route.params.importType)}}
      </h3>
    </div>

    <table class="o-table c-table--results" v-if="importData.fields">
      <thead>
      <tr>
        <th class="c-table-cell__valign-bottom">
          <div class="c-table__cell-content">
            {{$t('import.field')}}
          </div>
        </th>
        <th class="c-table-cell__valign-bottom">
          <div class="c-table__cell-content">
            {{$t('import.fieldSelected')}}
          </div>
        </th>
      </tr>
      </thead>
      <tbody class="c-table__body">
      <tr v-for="(fieldItem, field) in importData.fields[$route.params.importType].fields" :key="field">
        <td class="c-table__grouped-cells is-first">
          <div class="c-table__cell-content">
            {{$t('import.fieldname.' + field)}}
          </div>
        </td>
        <td class="c-table__grouped-cells is-first">
          <div class="c-table__cell-content">
            {{fieldItem.value}}
          </div>
        </td>
      </tr>
      </tbody>
    </table>


    <table class="o-table c-table--results">
      <thead>
      <tr>
        <th class="" colspan="5">
          <div class="c-table__cell-content" style="position: relative">
            <div style="float: right">
              <router-link exact :to="{ name: 'admin.event.discipline.import.view'}">
                X {{$t('close')}}
              </router-link>
            </div>
            {{$t('import.details')}} {{$t('import.group.' + $route.params.importType)}}
          </div>
        </th>
      </tr>
      <tr>
        <th class="c-table-cell__valign-bottom" colspan="2">
          <div class="c-table__cell-content">
            {{$t('import.item')}}
          </div>
        </th>
        <th class="c-table-cell__valign-bottom">
          <div class="c-table__cell-content">
            {{$t('import.prepared')}}
          </div>
        </th>
        <th class="c-table-cell__valign-bottom">
          <div class="c-table__cell-content">
            {{$t('import.processed')}}
          </div>
        </th>
        <th class="c-table-cell__valign-bottom">
          <div class="c-table__cell-content">
            {{$t('notes')}}
          </div>
        </th>
      </tr>
      </thead>
      <tbody class="c-table__body">
      <tr v-for="(item, index) in importData.fileData[$route.params.importType].items" :key="index">
        <td class="c-table__grouped-cells is-first">
          <div class="c-table__cell-content">
            {{index + 1}}
          </div>
        </td>
        <td class="c-table__grouped-cells">
          <div class="c-table__cell-content" style="text-align: left">
            <json-pretty :data="item.data" :deep="0" :showDoubleQuotes="false" />
          </div>
        </td>
        <td class="c-table__grouped-cells is-first">
          <div class="c-table__cell-content">
            {{$t('import.'+ item.type)}}
          </div>
        </td>
        <td class="c-table__grouped-cells is-first">
          <div class="c-table__cell-content">
            {{item.result ? $t('import.'+ item.result) : '-'}}
          </div>
        </td>
        <td class="c-table__grouped-cells is-first">
          <div class="c-table__cell-content" v-if="item.result === 'error'" style="text-align: left">
            <json-pretty :data="item.error" :deep="0" :showDoubleQuotes="false"/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: "import-view-summary",
    components: {
      'json-pretty': require('vue-json-pretty').default,
    },
    computed: {
      importData: function() {
        return this.$store.state.imports.importData;
      },
    },
    methods: {
    },
  }
</script>
