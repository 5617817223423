<template>
  <div class="c-main u-margin-bottom-small">
    <div class="c-main--flex-1">
      <h2 class="c-title c-title--page">{{$t('import')}} - {{importItem.filename}}</h2>
      <div class="o-layout">
        <div class="o-layout__item">
          <ul class="o-list o-list-bare c-input--extra-info">
            <li class="o-list__item">{{$t('time')}}: {{getTime()}}</li>
            <li class="o-list__item">{{$t('status')}}: {{importItem.status}}</li>
          </ul>
        </div>
      </div>
      <div class="o-layout u-margin-bottom-medium">
        <div class="o-layout__item">
          <router-view ></router-view>
        </div>
      </div>
    </div>

    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
        <button class="c-button c-button--secondary c-button--large" v-on:click.prevent="remove()">
          {{$t('delete')}}
        </button>
      </div>
      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <router-link exact :to="{ name: 'admin.event.discipline.import'}">
          <button class="c-button c-button--ghost c-button--large">{{$t("close")}}</button>
        </router-link>
        <button class="c-button c-button--primary c-button--large"
                :class="{'c-button--disabled': importItem.status !== 'preparing'}" v-on:click.prevent="submit()">
          {{$t('execute')}}
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
  import find from 'lodash/find';

  const moment = require('moment');

  export default {
    name: "import-view",
    computed: {
      importItem: function() {
        return find(this.$store.state.imports.items, item => {
          return item.id === this.$route.params.importId;
        });
      },
    },
    methods: {
      getTime: function() {
        return moment(this.importItem.date).format('YYYY-MM-DD hh:mm:ss');
      },
      remove: function() {
        this.$store.dispatch('import.delete', {importId: this.importItem.id}).then(() => {
          this.$navigation.goto({name: 'admin.event.discipline.import'});
        });
      },
      submit: function() {
        this.$store.dispatch('import.execute', this.importItem.id);
      },
    },
  }
</script>
